export default {
  'global.username': '用户名',
  'global.username.required': '请输入用户名',
  'global.password': '密码',
  'global.password.required': '请输入密码',
  'global.captcha': '验证码',
  'global.captcha.required': '请输入验证码',
  'global.captcha.get': '获取验证码',
  'global.login': '登录',
  'global.login.success': '登录成功',
  'global.login.fail': '登录失败',
  'global.signUp.success': '注册成功',
  'global.signUp.fail': '注册失败',
  'global.logout': '登出',
  'global.submit': '提交',
  'global.accountLogin': '账号密码登录',
  'global.accountLogin.fail': '账户或密码错误',
  'global.request.success': '请求成功',
  'global.request.fail': '请求失败',
  'global.action.success': '操作成功',
  'global.action.fail': '操作失败',
  'global.backHome': '返回首页',
  'global.404.message': '访问页面不存在!',
};
