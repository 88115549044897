import Cookies from 'js-cookie';
import { getDomain } from 'tldjs';

export const SESSION_ID = 'session_id';
export const USERSTATUS = 'userStatus';

export const setSessionId = (sessionId: string) => {
  const domain = getDomain(window.location.host);
  if (domain) {
    Cookies.set(SESSION_ID, sessionId, {
      domain,
      expires: 1,
    });
  } else {
    Cookies.set(SESSION_ID, sessionId);
  }
};

export const getSessionId = () => {
  return Cookies.get(SESSION_ID);
};

export const removeSessionId = () => {
  Cookies.remove(SESSION_ID);
};

export const setUserStatus = (userStatus: string) => {
  const domain = getDomain(window.location.host);
  if (domain) {
    Cookies.set(USERSTATUS, userStatus, {
      domain,
      expires: 1,
    });
  } else {
    Cookies.set(USERSTATUS, userStatus);
  }
};

export const getUserStatus = () => {
  return Cookies.get(USERSTATUS);
};

export const removeUserStatus = () => {
  return Cookies.remove(USERSTATUS);
};
