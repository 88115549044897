import PassportService from '@/services/passport';
import { USER_STATUS, toPassport, setAnyByStorage } from '@/utils/utils';
import {
  setSessionId,
  getSessionId,
  getUserStatus,
  setUserStatus,
  removeSessionId,
  removeUserStatus,
} from '@/utils/cookie';

const UserModel: any = {
  namespace: 'user',
  state: {
    accountId: '',
    username: '',
    sessionId: '',
  },
  effects: {
    *login({ params }: any, { call, put }: any) {
      try {
        //@ts-ignore
        const response = yield call(PassportService.signin, params);
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });
        return { success: true };
      } catch (error) {
        return { success: false, error: error };
      }
    },
    *signUp({ params }: any, { call, put }: any) {
      try {
        //@ts-ignore
        const response = yield call(PassportService.signUp, params);
        return {
          success: true,
          response,
        };
      } catch (error) {
        return { success: false, error: error };
      }
    },
    logout({ params }: any, { call, put }: any) {
      removeSessionId();
      removeUserStatus();
      localStorage.removeItem(USER_STATUS);
      localStorage.removeItem('user_dev');
      toPassport();
    },
    *autoLogin(_: any, { put, call }: any) {
      const userStorage = getUserStatus();
      const sessionId = getSessionId();
      const userStorageOfDev = JSON.parse(localStorage.getItem('user_dev') as string);
      if (userStorage && sessionId) {
        const user = JSON.parse(userStorage);
        user.sessionId = sessionId;
        yield put({
          type: 'changeLoginStatus',
          payload: user,
        });
        return user;
      } else if (userStorageOfDev) {
        if (sessionId) userStorageOfDev.sessionId = sessionId;
        yield put({
          type: 'changeLoginStatus',
          payload: userStorageOfDev,
        });
      } else {
        return toPassport();
      }
    },
  },
  reducers: {
    changeLoginStatus(state: any, { payload }: any) {
      const userStatus = {
        accountId: payload.accountId,
        username: payload.username,
      };
      setAnyByStorage(USER_STATUS, userStatus);
      setUserStatus(JSON.stringify(userStatus));
      setSessionId(payload.sessionId);
      return {
        ...state,
        ...userStatus,
        sessionId: payload.sessionId,
      };
    },
  },
};

export default UserModel;
