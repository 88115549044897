// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/opskitchen/swallow/frontend/data_exchange_fe/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/pages/widgets/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__layouts__BlankLayout' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__layouts__UserLayout' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__404' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__layouts__SecurityLayout' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__layouts__BasicLayout' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/taskList",
                "exact": true
              },
              {
                "path": "/tasklist",
                "name": "task.list",
                "icon": "unorderedList",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__TaskList' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/TaskList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/taskdetail",
                "name": "task.detail",
                "icon": "container",
                "hideInMenu": true,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__TaskDetail' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/TaskDetail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/result",
                "name": "task.detail",
                "icon": "container",
                "hideInMenu": true,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__TaskReport__components__FailContent' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/TaskReport/components/FailContent'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/report",
                "name": "task.report",
                "icon": "unorderedList",
                "hideInMenu": true,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__TaskReport' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/TaskReport'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/document",
                "name": "task.public",
                "icon": "container",
                "routes": [
                  {
                    "path": "/document/start",
                    "name": "start",
                    "icon": "UnorderedListOutlined",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__Document' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/Document'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__404' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__404' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__views__404' */'/Users/opskitchen/swallow/frontend/data_exchange_fe/src/pages/views/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
