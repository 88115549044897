import global from './zh-CN/global';
import menu from './zh-CN/menu';
import dataEx from './zh-CN/dataEx';
import errorMessage from './zh-CN/errorMessage';

export default {
  ...global,
  ...menu,
  ...dataEx,
  ...errorMessage,
};
