export default {
  'dataEx.task.list': '我的规则',
  'dataEx.task.detail': '规则详情',
  'dataEx.task.statueFlow': '规则创建流程',
  'dataEx.task.step.dataInterfacingPlanning': '数据对接规划',
  'dataEx.task.step.targetDataObject': '目标数据对象',
  'dataEx.task.step.sourceDataObject': '源数据对象',
  'dataEx.task.step.globalOverviewPage': '全局概览页面',
  'dataEx.task.btn.next': '下一步',
  'dataEx.task.btn.previous': '上一步',
  'dataEx.task.btn.skip': '跳过此过程',
  'dataEx.task.btn.backList': '返回列表',
  'dataEx.task.btn.gotoOverviewPage': '进入概览页面 ',
  'dataEx.task.change': '修改规则',
  'dataEx.timeInterval.interval': '执行间隔',
  'dataEx.timeInterval.custom': '自定义频次',
  'dataEx.checkedOptions.show': '显示打勾选项',
  'dataEx.checkedOptions.hide': '隐藏打勾选项',
  'dataEx.uncheckedOptions.show': '显示未打勾选项',
  'dataEx.uncheckedOptions.hide': '隐藏未打勾选项',
  'dataEx.system': '系统',
  'dataEx.serviceProvider': 'SAAS服务商',
  'dataEx.serviceProvider.select': '选择SAAS服务商',
  'dataEx.product.select': '选择产品',
  'dataEx.serviceProvider.lack.message': '列表中没有我想要的SAAS服务商?',
  'dataEx.serviceProvider.select.message.front': '你在',
  'dataEx.serviceProvider.select.message.after': '中有单独的服务器吗？是否使用了SAAS服务？',
  'dataEx.serviceProvider.reSelect': '重新选择SAAS服务商',
  'dataEx.serviceProvider.current': '当前SAAS服务商',
  'dataEx.serviceProvider.create.apply': '申请接入SAAS服务商',
  'dataEx.serviceProvider.name': 'SAAS服务商名字',
  'dataEx.website.official': '官网地址',
  'dataEx.apiCat': 'API分类',
  'dataEx.apiCat.select': '选择API分类',
  'dataEx.api.access.select': '选择接入API',
  'dataEx.api.access.required': '请选择接入API',
  'dataEx.api.change': '更换API',
  'dataEx.api.import': '导入API',
  'dataEx.api.add': '添加API',
  'dataEx.api.select': '请选择API',
  'dataEx.api.required': '请选择API',
  'dataEx.api.env': 'API环境',
  'dataEx.api.env.select': '请选择API环境',
  'dataEx.api.env.required': '请选择API环境',
  'dataEx.apiCall.add': '添加API调用',
  'dataEx.params': '入参',
  'dataEx.params.value': '入参值',
  'dataEx.params.value.input': '请输入入参值',
  'dataEx.params.value.required': '请输入入参值',
  'dataEx.returnData': '返回值',
  'dataEx.returnData.select': '请选择返回值',
  'dataEx.returnData.required': '请选择返回值',
  'dataEx.returnData.reference': '引用返回值',
  'dataEx.returnData.reference.select': '选择引用返回值',
  'dataEx.returnData.reference.inputInfo.before': '在公式中可使用',
  'dataEx.returnData.reference.inputInfo.after':
    '的形式, 在括号中填入引用返回值ID引用返回值,详见下方表格,点击复制图标可进行复制!',
  'dataEx.returnData.value': '返回结果',
  'dataEx.dockingRules': '对接规则',
  'dataEx.call.direct': '赋值',
  'dataEx.call.direct.input': '请输入赋值',
  'dataEx.call.original': '使用源返回值',
  'dataEx.call.target': '使用目标返回值',
  'dataEx.call.original.select': '选择使用返回值',
  'dataEx.call.function': '函数转换',
  'dataEx.call.api': 'API转换',
  'dataEx.call.api.returnData.info.before': '将',
  'dataEx.call.api.returnData.info.after': '返回至',
  'dataEx.requestArgs.link': '建立关联',
  'dataEx.requestArgs.unlink': '取消关联',
  'dataEx.unset': '未设置',
  'dataEx.dataSource': '数据来源',
  'dataEx.dataSource.select': '选择数据来源',
  'dataEx.dataSource.select.other': '选择其它数据来源',
  'dataEx.returnDataValue': '引用返回值',
  'dataEx.selfDefine': '自定义值',
  'dataEx.variable': '变量',
  'dataEx.variable.exist': '已存在变量',
  'dataEx.variable.select': '请选择变量',
  'dataEx.variable.required': '请选择变量',
  'dataEx.constant': '常量',
  'dataEx.constant.exist': '已存在常量',
  'dataEx.orderNumber': '订单号',
  'dataEx.constant.select': '请选择变量',
  'dataEx.constant.required': '请选择变量',
  'dataEx.id': 'ID',
  'dataEx.value': '值',
  'dataEx.value.input': '请输入值',
  'dataEx.value.required': '请输入值',
  'dataEx.exitPoint': '退出点',
  'dataEx.filterConditions': '过滤条件',
  'dataEx.validator.rule': '验证规则',
  'dataEx.validator.rule.select': '请选择验证规则',
  'dataEx.validator.rule.create': '新建验证规则',
  'dataEx.validator.rule.update': '修改验证规则',
  'dataEx.expression': '表达式',
  'dataEx.expression.input': '请输入表达式',
  'dataEx.function': '公式',
  'dataEx.history': '历史记录',
  'dataEx.dataType.notSame': '数据类型不统一',
  'dataEx.dataType.notSame.params.message': '入参的数据类型不统一',
  'dataEx.dataType.notSame.variable.message': '入参与变量数据类型不统一',
  'dataEx.dataType.notSame.returnData.message': '入参与引用返回值数据类型不统一',
  'dataEx.dataType.assignment successful': '赋值成功',

  'dataEx.addFilteringMethod.successfully': '添加过滤方法成功',
  'dataEx.addFilteringMethod.failed': '添加过滤方法失败',
  'dataEx.parameter.successfully': '添加参数成功',
  'dataEx.parameter.failed': '添加参数失败',

  'dataEx.parameter': '参数',
  'dataEx.validatorType': '验证规则类型',
  'dataEx.validatorType.select': '请选择验证规则类型',
  'dataEx.validatorType.required': '请选择验证规则类型',
  'dataEx.parameter.number': '参数数量',
  'dataEx.dataType': '数据类型',
  'dataEx.dataType.select': '请选择数据类型',
  'dataEx.dataType.required': '请选择数据类型',
  'dataEx.invertResult': '取反',

  'dataEx.timedPlan': '定时计划',
  'dataEx.unnamed': '未命名',
  'dataEx.source': '源',
  'dataEx.source.system': '源系统',
  'dataEx.target': '目标',
  'dataEx.target.system': '目标系统',
  'dataEx.to': '到',
  'dataEx.delete.task': '是否删除此任务',

  'dataEx.unknown': '未知',
  'dataEx.singleRuler.set': '设置单次规则',
  'dataEx.batchRule.set': '设置批量规则',
  'dataEx.batch.run': '批量执行',
  'dataEx.single.run': '执行',
  'dataEx.single.timer': '定时器',
  'dataEx.namingRules': '命名规则',
  'dataEx.batchRunning.failed': '执行失败',
  'dataEx.singleRunning.successfully': '执行成功',

  'dataEx.runningResult.message': '你可以点击下方按钮或该规则的历史记录查看 执行结果。',
  'dataEx.executing.successfully': '后端执行中',

  'dataEx.task.incomplete': '当前任务的状态未完备',
  'dataEx.task.edit': '协议参数编辑',
  'dataEx.task.delete': '删除',

  'dataEx.from': '从',
  'dataEx.taobao': '淘宝',
  'dataEx.cainiao': '菜鸟',
  'dataEx.iWantTo': '我想把',
  'dataEx.system.inputTo': '系统导入到',
  'dataEx.inTheSystem': '系统中',
  'dataEx.example': '示例: 我想把 订单 从 淘宝 系统 导入到 菜鸟 系统 中',
  'dataEx.orders': '订单',
  'dataEx.oneSentenceDescription': '一句话描述',
  'dataEx.confirm.delete.api': '确认删除当前API吗?',

  'dataEx.taskResCount.total': '总数:',
  'dataEx.taskResCount.pass': '成功:',
  'dataEx.taskResCount.error': '失败:',
  'dataEx.taskResCount.filter': '过滤:',

  'dataEx.taskResDetail.taskName': '任务名称:',
  'dataEx.taskResDetail.result': '结果:',
  'dataEx.taskResDetail.reason': '原因:',
  'dataEx.taskResDetail.execute': '执行时间:',
  'dataEx.taskResDetail.notSelectedYet': '暂未选择',
  'dataEx.taskResDetail.addTheFirstNode': '添加首个节点',
  'dataEx.taskResDetail.addTheFirstAPI': '添加首个API',

  'dataEx.taskTimer.specificTime': '特定时间段执行',
  'dataEx.taskTimer.oneTime': '一次性计划',
  'dataEx.taskTimer.fixed': '固定',
  'dataEx.taskTimer.customization': '自定义',
  'dataEx.taskTimer.frequencyTypeSwitching': '频次类型切换',
  'dataEx.taskTimer.exitFrequencySetting': '退出频次设置',
  'dataEx.taskTimer.whetherToReselectTimingRules': '是否重新选择定时规则',

  'dataEx.taskReport.executionResultsPage': '执行结果页',
  'dataEx.taskReport.callResults': '调用结果',
  'dataEx.taskReport.missionInformation': '任务信息',
};
