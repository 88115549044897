import PassportRequest from '@/utils/request/okapi.passport';
import { Passport, PassportResult } from '@/interface/passport.interface';

export default {
  signin: (params: Passport): Promise<PassportResult> =>
    PassportRequest.post({
      api: {
        name: 'passport.user.login',
        version: '1.0',
      },
      params,
    }),
  reSignin: (params: any) =>
    PassportRequest.post({
      api: {
        name: 'passport.user.confirmSignIn',
        version: '1.0',
      },
      params,
    }),
  logout: (params: null) =>
    PassportRequest.post({
      api: {
        name: 'passport.user.logout',
        version: '1.0',
      },
      params,
    }),
  getCaptcha: () =>
    PassportRequest.post({
      api: {
        name: 'passport.captcha.get',
        version: '1.0',
      },
    }),
  updatePassword: (params: any) =>
    PassportRequest.post({
      api: {
        name: 'passport.user.updatePassword',
        version: '1.0',
      },
      params,
    }),
  signUp: (params: any) =>
    PassportRequest.post({
      api: {
        name: 'passport.account.register',
        version: '1.0',
      },
      params,
    }),
};
