/* eslint-disable no-prototype-builtins */
import axios, { AxiosResponse, CancelTokenSource, CancelTokenStatic } from 'axios';
import qs from 'qs';
// @ts-ignore
import Fingerprint from 'fingerprintjs';
import { calcHash } from './obfuscated';
import EventEmitter from '@/utils/eventEmitter';
import { getSessionId } from '@/utils/cookie';

// type ApiInfo = {
//   api: {};
//   params: any;
// };

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      if (data && data.errorCode === 'AD.SessionNotExists') {
        EventEmitter.emit('openReLoginForm');
        return response;
      }
    }
    return response;
  },
  (error: any) => {
    if (error.response && error.response.data) {
      if (error.response.data.errorCode === 'AD.SessionNotExists') {
        EventEmitter.emit('openReLoginForm');
      }
    }
    return error;
  },
);

class OkApiRequest {
  public baseUrl: string;
  public httpMethod: string;
  public appKey: string;
  public appMarketId: number;
  public appVersion: string;
  public secret: string;
  constructor(baseUrl: string, appKey?: string, secret?: string) {
    this.baseUrl = baseUrl;
    this.httpMethod = 'post';
    this.appKey = appKey || '1001520';
    this.secret = secret || '';
    this.appMarketId = 678;
    this.appVersion = '1.0';
  }

  get(data: any) {
    this.httpMethod = 'get';
    return this.callApi(data);
  }

  post(data: any) {
    this.httpMethod = 'post';
    return this.callApi(data);
  }

  cancel() {
    // this.source.cancel('Operation canceled by the user.');
  }

  callApi(obj: any, httpDefaultOpts: any = undefined) {
    if (!httpDefaultOpts) {
      httpDefaultOpts = this.$_ok_getOptions(obj);
    }
    return axios(httpDefaultOpts).then(async (response: any) => {
      // if (response) {
      //   return Promise.resolve(response.data);
      // }

      // if (
      //   response &&
      //   response.data &&
      //   response.data.hasOwnProperty('success') &&
      //   response.data.success
      // ) {
      //   return Promise.resolve(response.data.data);
      // } else
      if (
        response &&
        response.data &&
        response.data.hasOwnProperty('errorCode') &&
        response.data.errorCode
      ) {
        const i18nNamespace = obj.api.name.split('.')[0];
        const errorCode = i18nNamespace + '.message.apiError.' + response.data.errorCode;
        return Promise.reject(response.data.errorCode);
      } else if (response) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(new Error('protocol error'));
    });
  }

  $_ok_getOptions(data: any) {
    // let CancelToken:CancelTokenStatic = axios.CancelToken;
    // let source:CancelTokenSource = CancelToken.source();
    const cancelToken = data.cancelToken || '';
    const signKey = 'OA-Sign';
    const paramsJson = JSON.stringify(data.params);
    const deviceId = new Fingerprint({
      canvas: true,
    }).get();
    const sessionId = '' + getSessionId();
    const timeStamp = Date.now() * 1000 + new Date().getMilliseconds();
    // if (Object.prototype.toString.call(data.params) === '[object Object]') {
    //   sessionStorage.setItem(
    //     signKey,
    //     deviceId + sessionId + data.api.name + data.api.version + paramsJson + timestamp,
    //   );
    // } else {
    //   sessionStorage.setItem(
    //     signKey,
    //     deviceId + sessionId + data.api.name + data.api.version + timestamp,
    //   );
    // }
    // obfuscated.calcHash();
    // const sign = sessionStorage.getItem(signKey);
    let OASign = `${signKey}${sessionId}${data.api.name}${data.api.version}${timeStamp}`;
    if (Object.prototype.toString.call(data.params) === '[object Object]') {
      OASign = OASign.concat(paramsJson);
    }
    const hashedOASign = calcHash(OASign, this.secret);
    if (!data.hasOwnProperty('callType')) {
      data.callType = 'post';
    }
    const axiosOptions = {
      method: data.callType,
      url: this.baseUrl + data.api.name.trim(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'OA-Session-Id': sessionId,
        'OA-App-Key': this.appKey,
        'OA-App-Market-ID': this.appMarketId,
        'OA-App-Version': this.appVersion,
        'OA-Device-Id': deviceId,
        'OA-Sign': hashedOASign,
      },
      data: {
        api: data.api.name,
        version: data.api.version,
        timestamp: timeStamp,
        params: paramsJson,
      },
      transformRequest: function (requestData: any) {
        return qs.stringify(requestData);
      },
      transformResponse: function (response: string) {
        const result = JSON.parse(response);

        if (result.hasOwnProperty('errorCode')) {
          return result;
        } else if (result.hasOwnProperty('success')) {
          return result.data;
        } else {
          // console.log(response);
        }
      },
      timeout: 1000000,
      cancelToken,
    };
    return axiosOptions;
  }
}

export default OkApiRequest;
