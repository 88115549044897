export default {
  'menu.home': '主页',
  'menu.dashboard': '仪表盘',
  'menu.profile': '个人资料',
  'menu.task.list': '任务列表',
  'menu.task.report': '结果报告',
  'menu.task.public': '文档',
  'menu.task.public.start': 'Getting Started',
  'menu.task.statuteFlow': '规程流程',
  'menu.task.detail': '规则详情',
};
