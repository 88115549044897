const defaultSetting: any = {
  navTheme: 'light',
  primaryColor: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'SwallowSoft',
  pwa: false,
  iconfontUrl: '',
};

export default defaultSetting;
