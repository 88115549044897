export default {
  passportUrl: {
    dev: 'https://gw.swallowsoft.com:8443?debug=',
    pro: 'https://gw.swallowsoft.com:8443?debug=',
  },
  accountUrl: {
    // 账号
    dev: 'https://gw.swallowsoft.com:8443?debug=',
    pro: 'https://gw.swallowsoft.com:8443?debug=',
  },
  dataExchange: {
    dev: 'https://gw.swallowsoft.com:8443?debug=',
    // dev: 'http://192.168.31.71:8070?debug=',
    // dev: 'http://192.168.31.105:8070?debug=',
    pro: 'https://gw.swallowsoft.com:8443?debug=',
  },
  apiDesigner: {
    dev: 'https://gw.swallowsoft.com:8443?debug=',
    pro: 'https://gw.swallowsoft.com:8443?debug=',
  },
  runUrl: {
    dev: 'http://192.168.31.116:7890/',
    // dev: 'https://gw.swallowsoft.com:8443/',
    pro: 'https://gw.swallowsoft.com:8443/',
  },
  dataTypeUrl: {
    dev: 'https://gw.swallowsoft.com:8443?debug=',
    pro: 'https://gw.swallowsoft.com:8443?debug=',
  },
  cookieExpires: 1,
};
