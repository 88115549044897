const GlobalModel: any = {
  namespace: 'global',
  state: {
    collapsed: true,
  },
  effects: {},
  reducers: {
    changeLayoutCollapsed(state = { notices: [], collapsed: true }, { payload }: any): any {
      return {
        ...state,
        collapsed: payload,
      };
    },
  },
};

export default GlobalModel;
