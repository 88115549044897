export default {
  'global.username': '用户名',
  'global.username.required': '请输入用户名',
  'global.password': '密码',
  'global.password.required': '请输入密码',
  'global.captcha': '验证码',
  'global.captcha.required': '请输入验证码',
  'global.captcha.get': '获取验证码',
  'global.login': '登录',
  'global.login.success': '登录成功',
  'global.login.fail': '登录失败',
  'global.logout': '登出',
  'global.submit': '提交',
  'global.add': '新增',
  'global.create': '创建',
  'global.update': '更新',
  'global.edit': '编辑',
  'global.save': '保存',
  'global.delete': '删除',
  'global.cancel': '取消',
  'global.required': '必填',
  'global.notRequired': '选填',
  'global.login.account': '账号密码登录',
  'global.login.signUp': '账号注册',
  'global.login.account.fail': '账户或密码错误',
  'global.login.account.captchaNotMatch': '验证码不匹配',
  'global.login.account.accountNotExists': '账号不存在',
  'global.request.success': '请求成功',
  'global.request.failure': '请求失败',
  'global.action.success': '操作成功',
  'global.action.failure': '操作失败',
  'global.backHome': '返回首页',
  'global.404.message': '访问页面不存在!',
  'global.search': '搜索',
  'global.year': '年',
  'global.month': '月',
  'global.week': '星期',
  'global.day': '日',
  'global.hours': '小时',
  'global.minutes': '分钟',
  'global.seconds': '秒',
  'global.content.edit': '编辑内容',
  'global.linked': '已关联',
  'global.unlinked': '未关联',
  'global.next': '下一步',
  'global.previous': '上一步',
  'global.addFailed': '添加失败',
  'global.addSuccessfully': '添加成功',
  'global.createFailed': '创建失败',
  'global.createSuccessfully': '创建成功',
  'global.deleteSuccessfully': '删除成功',
  'global.deleteFailed': '删除失败',
  'global.name': '名称',
  'global.name.input': '请输入名称',
  'global.name.required': '请输入名称',
  'global.ok': '确定',
  'global.request.failed': '请求失败',
  'global.request.successfully': '请求成功',
  'global.running.successfully': '执行成功',
  'global.running.failed': '执行失败',
  'global.result.look': '查看结果',
  'global.success': '成功',
  'global.filter': '跳过',
  'global.failed': '失败',
  'global.sourceData': '源数据',
  'global.result': '结果',
  'global.environment': '环境',
  'global.startTime': '执行时间',
  'global.updateSuccessful':'更新成功',
  'global.updateFail':'更新失败',
  'global.settingSuccessful':'设置成功',
  'global.settingFail':'设置失败'
};
