import { parse } from 'querystring';
import { notification } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';

export const USER_STATUS = 'userStatus';

export const setAnyByStorage = (name: string, obj: any) => {
  localStorage.setItem(name, JSON.stringify(obj));
};

export const getAnyByStorage = (name: string) => {
  const data = localStorage.getItem(name);
  if (!data) return false;
  return JSON.parse(data);
};
export const removeStorage = (name: string) => {
  if (localStorage.getItem(name)) {
    localStorage.removeItem(name);
  }
};

export const iconFontUrl = '//at.alicdn.com/t/font_2355177_7oq2yhth20p.js';
export const IconFont = createFromIconfontCN({
  scriptUrl: iconFontUrl,
});

interface Node<T> {
  key: string;
  pid: number;
  created: string;
  modified: string;
  title: string;
  leftValue: number;
  rightValue: number;
  children: T[];
}

export const jsonToMpt = <T extends Partial<Node<T>> = any>(arrData: T[]): T[] => {
  if (!Array.isArray(arrData)) throw Error('json to mpt parameter must be a array');
  let left = 2;
  const result: T[] = [];

  const eachArr = (arr: T[]) => {
    let i = 0;
    const len: number = arr.length;
    for (i; i < len; i++) {
      const item: T = arr[i];
      item.leftValue = left++;
      const item2: T = JSON.parse(JSON.stringify(item));
      delete item2.children;
      delete item2.key;
      delete item2.pid;
      delete item2.created;
      delete item2.modified;
      delete item2.title;
      result.push(item2);
      if (item.children && item.children.length > 0) {
        eachArr(item.children);
      }
      item2.rightValue = left++;
    }
  };
  eachArr(arrData);
  return result;
};

interface MPT<T> {
  id: number;
  depth: number;
  children?: T[];
  pid?: number;
}

export const mptToJson = <T extends MPT<T> = any>(
  arr: T[],
): { result: T[]; resultMap: { [_id: number]: T } } => {
  if (!Array.isArray(arr)) throw Error('mpt to json parameter must be a array');
  const stack: T[] = [];
  const result: T[] = [];
  const resultMap: { [_id: number]: T } = {};
  let last: T | null = null;
  let children = null;

  const judgeDepth = (item: T) => {
    if (!last) {
      stack.push(item);
      result.push(item);
      last = item;
      children = result;
    } else if (item.depth === last.depth) {
      stack.pop();
      last = stack[stack.length - 1];
      if (last) {
        children = last.children!;
        item.pid = last.id;
      } else {
        children = result;
      }
      children.push(item);
      stack.push(item);
      last = item;
    } else if (item.depth > last.depth) {
      children = last.children;
      children!.push(item);
      item.pid = last.id!;
      stack.push(item);
      last = item;
    } else {
      stack.pop();
      last = stack[stack.length - 1];
      judgeDepth(item);
    }
  };

  const eachArr = (nodeArr: T[]) => {
    nodeArr.forEach((item: T) => {
      resultMap[item.id!] = item;
      item.children = [];
      item.pid = undefined;
      judgeDepth(item);
    });
  };

  eachArr(arr);

  return {
    result,
    resultMap,
  };
};

export function timeStamp(StatusMinute: number) {
  if (!StatusMinute) return 0;
  const month = parseInt(
    String(StatusMinute / 31 / 60 / 24 > 11 ? 12 : StatusMinute / 31 / 60 / 24),
  );
  // eslint-disable-next-line radix
  const day = parseInt(String((StatusMinute / 60 / 24) % 31));
  // eslint-disable-next-line radix
  const hour = parseInt(String((StatusMinute / 60) % 24));
  // eslint-disable-next-line radix
  const min = parseInt(String(StatusMinute % 60));
  let res = '';
  if (month > 0) {
    res = `${month},`;
  } else {
    res = `${0},`;
  }
  if (day > 0) {
    res += `${day},`;
  } else {
    res += `${0},`;
  }
  if (hour > 0) {
    res += `${hour},`;
  } else {
    res += `${0},`;
  }
  if (min > 0) {
    res += `${parseFloat(String(min))}`;
  } else {
    res += `${0}`;
  }
  return res;
}
export const deleteChildren = (item: any): any => {
  if (Array.isArray(item) && item.length > 0) {
    item.map((target) => {
      if (target.children && target.children.length === 0) {
        delete target.children;
      } else {
        deleteChildren(target.children);
      }
    });
  }
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const openNotificationWithIcon = (
  type: 'success' | 'error',
  title?: string,
  message?: any,
) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export function toPassport() {
  const url = window.location.origin;
  let targetUrl = `https://passport.swallowsoft.com/?returnUrl=${url}`;
  if (process.env.NODE_ENV === 'development') {
    targetUrl += '&env=dev';
  }
  return window.location.assign(targetUrl);
}
